import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';

@Component({
  selector: 'app-risk-matrix',
  standalone: true,
  imports: [CommonModule, NgxEchartsDirective],
  templateUrl: './risk-matrix.component.html',
  styleUrl: './risk-matrix.component.scss',
  providers: [
    provideEcharts(),
  ]
})
export class RiskMatrixComponent implements OnChanges {

  @Input() impactInput?: number;
  @Input() probabilityInput?: number;

  options: echarts.EChartsOption = {};

  // Inputs for Impact (rows) and Probability (columns)
  impact: number[] = [1, 2, 3, 4, 5]; // From Catastrophic to Negligible
  probability: number[] = [1, 2, 3, 4, 5]; // From Improbable to Frequent

  constructor() {
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['impactInput'] || changes['probabilityInput']) {
      this.initializeChart();
    }
  }

  initializeChart() {
    const highlightedCell = this.getHighlightedCell();
    this.options = {
      tooltip: {
        formatter: (params: any) =>
          `Impact: ${this.impact[params.data.value[1]]}, Probability: ${this.probability[params.data.value[0]]}<br>Risk: ${params.data.value[2]}`,
      },
      xAxis: {
        type: 'category',
        name: 'Probability',
        nameLocation: 'middle',
        nameTextStyle: { fontSize: 20, fontWeight: 'bold' },
        nameGap: 40,
        data: ['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent'],
        axisTick: { show: false },
        axisLabel: { fontSize: 12 },
      },
      yAxis: {
        type: 'category',
        name: 'Impact',
        nameLocation: 'middle',
        nameTextStyle: { fontSize: 20, fontWeight: 'bold' },
        nameGap: 80,
        data: ['Negligible', 'Low', 'Moderate', 'Significant', 'Catastrophic'],
        axisTick: { show: false },
        axisLabel: { fontSize: 12 },
      },

      visualMap: {
        min: 1,
        max: 25,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: -20,
        inRange: {
          color: ['#1EB768', '#B2D34A', '#F7A232', '#E86829', '#DC3622'],
        },
        selected: {
          [String(this.getCellValueForHighlight())]: true,
        }
      },
      series: [
        {
          type: 'heatmap',
          data: this.generateMatrixData(highlightedCell),
          label: {
            show: true,
            color: '#000',
            fontWeight: 'bold',
            formatter: (params: any) => params.data[2],
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  generateMatrixData(highlightedCell: [number, number] | null) {
    const data: { value: number[]; itemStyle?: object }[] = [];
    this.impact.forEach((impactValue, rowIndex) => {
      this.probability.forEach((probabilityValue, colIndex) => {
        const value = impactValue * probabilityValue;
        const isHighlighted =
          highlightedCell &&
          highlightedCell[0] === colIndex &&
          highlightedCell[1] === rowIndex;

        if (highlightedCell && highlightedCell[0] === colIndex && highlightedCell[1] === rowIndex) {
          data.push({
            value: [colIndex, rowIndex, value],
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              borderColor: 'black',
              borderWidth: 1
            },
          });
        } else {
          data.push({ value: [colIndex, rowIndex, value] });
        }
      });
    });
    return data;
  }

  getCellValueForHighlight(): number | null {
    const highlightedCell = this.getHighlightedCell();
    if (highlightedCell) {
      const [colIndex, rowIndex] = highlightedCell;
      return this.impact[colIndex] * this.probability[rowIndex];
    }
    return null;
  }


  getHighlightedCell(): [number, number] | null {
    if (this.impactInput != null && this.probabilityInput != null) {
      const impactIndex = this.impact.indexOf(this.impactInput);
      const probabilityIndex = this.probability.indexOf(this.probabilityInput);
      if (impactIndex !== -1 && probabilityIndex !== -1) {
        return [probabilityIndex, impactIndex];
      }
    }
    return null;
  }
}
