<p-dialog [(visible)]="visible" [header]="'Header'" contentStyleClass="dialogContent"
    [style]="{width: '60vw', height: '70vh', display: 'flex'}" [modal]="true" (onHide)="cancel()">
    <ng-template pTemplate="header">
        <div class="font-bold">{{title}}</div>
    </ng-template>
    <ng-template pTemplate="content">
        @if(isLoading){
        <p-skeleton width="100%" height="100%" />
        }
        @else{
        <div class="h-full max-h-[65vh] flex flex-row justify-content-between p-4">
            <!-- Left Panel -->
            <div
                class="h-full col-6 border-right-1 border-gray-200 flex flex-col flex-1 ml-4 mr-1 bg-white dialog-card max-w-[48%]">
                <h2 class="font-bold pb-2">All {{selectionType}}</h2>
                <span class="p-input-icon-left w-full mb-3">
                    <p-inputGroup class="search-bar">
                        <p-inputGroupAddon>
                            <i class="pi pi-search text-arena-orange"></i>
                        </p-inputGroupAddon>
                        <input pInputText type="text" class="w-full" (input)="onFilter()"
                            placeholder="Search {{selectionType}}" [(ngModel)]="searchValue"
                            [style]="{outline: 'none'}" />
                    </p-inputGroup>
                </span>
                <div class="mb-2 ml-2">
                    <p-checkbox [binary]="true" [(ngModel)]="selectAll" (onChange)="toggleSelectAll($event)"
                        label="Select All ({{filteredData.length}})"></p-checkbox>
                </div>
                <div #leftList class="left-list" infiniteScroll [infiniteScrollDistance]="0"
                    [infiniteScrollThrottle]="500" (scrolled)="infiniteScroll && onScroll()" [scrollWindow]="false">
                    @if(!isLoading && isItemsLoading) {
                    <div class="skeleton-card mt-4">
                        <div class="border-round border-1 surface-border surface-card">
                            <div class="grid grid-rows-4 gap-6">
                                <p-skeleton width="auto" height="3.5rem" />
                                <p-skeleton width="auto" height="3.5rem" />
                                <p-skeleton width="auto" height="3.5rem" />
                                <p-skeleton width="auto" height="3.5rem" />
                                <p-skeleton width="auto" height="3.5rem" />
                            </div>
                        </div>
                    </div>
                    } @else {
                    <div *ngFor="let item of filteredData" class="mb-2">
                        @if(!isDocument(item)) {
                        <div class="d-flex flex-row ml-2">
                            <p-checkbox [binary]="true" [(ngModel)]="item.selected" [label]="item.name"
                                (onChange)="updateSelectedItems()" />
                        </div>
                        } @else {
                        <div class="flex p-4 border rounded-2xl shadow-sm mb-1 gap-y-1 gap-x-4 w-full">
                            <div class="flex flex-col gap-1 w-[75%]">
                                <p-checkbox [binary]="true" [(ngModel)]="item.selected" [label]="item.name"
                                    (onChange)="updateSelectedItems()" class="font-semibold text-gray-900" />
                                @if(getSummary(item)) {
                                <span class="text-sm text-gray-500 truncate-summary">
                                    <markdown [data]="getSummary(item)"></markdown>
                                </span>
                                } @else {
                                <div class="text-sm text-gray-500">No Summary available</div>
                                }
                            </div>
                            <div class="flex flex-col gap-0.5 w-[25%]">
                                <div class="flex gap-0.5 items-center">
                                    <img [src]="getCreatedBy(item) | userAvatar" class="rounded-lg"
                                        alt="Profile Picture" width="20" height="20" />
                                    <span class="text-sm text-gray-900 ml-2 overflow-x-auto truncate"
                                        pTooltip="{{ getCreatedBy(item) | fullName }}" tooltipPosition="right">{{
                                        getCreatedBy(item) | fullName }}</span>
                                </div>
                                <div class="flex text-sm text-gray-500 truncate">{{ getUpdatedAt(item) | date:
                                    'mediumDate' }}</div>
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
            <!-- Right Panel -->

            <div
                class="col-6 border-right-1 border-gray-200 flex flex-col flex-1 ml-4 mr-4 bg-white dialog-card max-w-[48%]">
                <h2 class="font-bold pb-2">Selected {{selectionType}}</h2>
                <div class="right-list">
                    <div *ngFor="let item of selectedItems" class="mb-2 flex selectedItems w-full">
                        @if(!isDocument(item)) {
                        <div class="flex flex-row items-center ml-2 w-full justify-between">
                            <div>{{item.name}}</div>
                            <p-button pButton icon="pi pi-trash"
                                class="p-button-danger rounded-xl border border-gray-200 shadow-sm p-button-text"
                                (click)="removeItem(item)"></p-button>
                        </div>
                        } @else {
                        <div class="flex p-4 border rounded-2xl shadow-sm mb-1 gap-y-1 gap-x-4 w-full">
                            <div class="flex flex-col gap-1 w-[75%]">
                                <div class="font-semibold text-gray-900">{{item.name}}</div>
                                @if(getSummary(item)) {
                                <span class="flex text-sm text-gray-500 truncate-summary"
                                    pTooltip="{{ getSummary(item) }}" tooltipPosition="left">{{ getSummary(item)
                                    }}</span>
                                } @else {
                                <div class="flex text-sm text-gray-500">No Summary available</div>
                                }
                            </div>
                            <div class="flex flex-col gap-0.5 w-[25%]">
                                <div class="flex gap-0.5 items-center">
                                    <img [src]="getCreatedBy(item) | userAvatar" class="rounded-lg"
                                        alt="Profile Picture" width="20" />
                                    <span class="text-sm text-gray-900 ml-2 truncate"
                                        pTooltip="{{ getCreatedBy(item) | fullName }}"
                                        tooltipPosition="right">{{getCreatedBy(item) | fullName }}</span>
                                </div>
                                <div class="flex text-sm text-gray-500 truncate">{{ getUpdatedAt(item) | date:
                                    'mediumDate' }}</div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        }
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button label="Cancel" severity="primary" (onClick)="cancel()" class="custom-icon-size mr-2"
            styleClass="w-100 p-2 px-3 rounded-xl text-sm" [outlined]="true" />
        <p-button label={{label}} severity="primary" (onClick)="submitSelection()" class="custom-icon-size"
            [disabled]="selectedItems.length === 0"
            styleClass="w-100 bg-arena-orange p-2 px-3 rounded-xl text-white text-sm" [outlined]="true"
            [loading]="saveLoader" />
    </ng-template>
</p-dialog>