import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SkeletonModule } from 'primeng/skeleton';
import { IContract } from '../../../contract-management/models/contract-management.model';
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { FullNamePipe } from '../../pipes/fullName.pipe';
import { IInfiniteScrollEvent, InfiniteScrollDirective } from "ngx-infinite-scroll"
import { MarkdownModule } from "ngx-markdown";
import _ from "lodash";
import { TooltipModule } from 'primeng/tooltip';

export interface SelectionDialogBase {
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-selection-dialog',
  standalone: true,
  imports: [DialogModule, InputGroupModule, CheckboxModule, InputGroupAddonModule, ButtonModule, FormsModule, SkeletonModule, CommonModule, UserAvatarPipe, FullNamePipe, InfiniteScrollDirective, TooltipModule, MarkdownModule],
  templateUrl: './selection-dialog.component.html',
  styleUrl: './selection-dialog.component.scss'
})
export class SelectionDialogComponent<T extends SelectionDialogBase> implements OnInit {

  @ViewChild('leftList') leftListRef!: ElementRef;
  @Input() visible: boolean = false
  @Input() title: string = ''
  @Input() selectionType: string = ''
  @Input() label: string = ''
  @Input() saveLoader: boolean = false
  @Input() isLoading: boolean = false
  @Input() infiniteScroll: boolean = false
  @Input() isItemsLoading: boolean = false

  //data input
  private _data: T[] = [];
  @Input()
  set data(value: T[]) {
    this._data = value;
    this.filteredData = [...this._data];
    this.updateSelectedItems();
  }
  get data() {
    return this._data;
  }


  //new data input - after scroll event
  private _newData?: T[] = [];
  @Input()
  set newData(value: T[]) {
    this._newData = value;
    this.filteredData.push(... this._newData);
    this.data = [... this.filteredData];
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }
  get newData() {
    return this._newData || [];
  }

  @Output() onSelectionSubmit = new EventEmitter<T[]>();
  @Output() onDialogClose = new EventEmitter<void>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onScrollEndReached = new EventEmitter<string>();
  @Output() onSearchItem = new EventEmitter<string>();


  searchValue: string = ''
  selectAll: boolean = false
  previousScrollTop: number = 0

  filteredData: T[] = []
  selectedItems: T[] = []
  constructor() {
  }

  ngOnInit() {

  }

  onFilter() {
    if (this.infiniteScroll) {
      this.isItemsLoading = true;
      this.searchItems();
    } else {
      this.filterItems();
    }
  }

  filterItems() {
    this.filteredData = (this.data).filter(item => (item.name.toLowerCase()).includes(this.searchValue.toLowerCase()));
    this.updateSelectAllState();
  }

  searchItems = _.debounce(() => {
    this.onSearchItem.emit(this.searchValue);
  }, 500)

  onScroll() {
    this.isItemsLoading = true;
    this.previousScrollTop = this.leftListRef.nativeElement.scrollTop;
    this.fetchItems();
  }

  fetchItems = _.debounce(() => {
    this.onScrollEndReached.emit(this.searchValue);
  }, 500)

  scrollToBottom() {
    if (this.leftListRef && this.leftListRef.nativeElement) {
      this.leftListRef.nativeElement.scrollTop = this.previousScrollTop;
    }
  }

  isDocument(item: any) {
    return (item as IContract).summary !== undefined && (item as IContract).createdBy !== undefined && (item as IContract).updatedAt !== undefined;
  }

  getSummary(item: any) {
    return (item as IContract).summary;
  }

  getCreatedBy(item: any) {
    return (item as IContract).createdBy;
  }

  getUpdatedAt(item: any) {
    return (item as IContract).updatedAt;
  }

  toggleSelectAll(event: CheckboxChangeEvent) {
    this.filteredData.forEach(item => {
      item.selected = event.checked;
    });
    this.updateSelectedItems();
  }

  updateSelectedItems() {
    this.selectedItems = this.data.filter(item => item.selected);
    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const filteredSelected = this.filteredData.filter(item => item.selected);
    this.selectAll = filteredSelected.length === this.filteredData.length;
  }

  removeItem(item: any & { selected?: boolean }) {
    item.selected = false;
    this.updateSelectedItems();
  }

  cancel() {
    this.searchValue = '';
    this.visible = false
    this.visibleChange.emit(this.visible);
    this.onDialogClose.emit();
  }


  submitSelection() {
    this.onSelectionSubmit.emit(this.selectedItems);
  }
}
