import { Pipe, PipeTransform } from '@angular/core';
import { GENDER, IShareUser, IUser } from '../../@core/models/model';
import { environment } from '../../../environments/environment.dev';

@Pipe({
    standalone: true,
    name: 'userAvatar'
})

export class UserAvatarPipe implements PipeTransform {
    transform(user: Partial<IUser> | null | undefined | IShareUser | string): string {
        if (!user) {
            return randomAvatarGenerator(0);
        }
        if ((user as IUser).profilePicture?.url) {
            return `${environment.s3Url}/${(user as IUser).profilePicture.url}?timestamp=${new Date().getTime()}`;
        }
        let randomAvatar = randomAvatarGenerator((user as IUser)?.gender || 0);
        return randomAvatar;
    }
}

export function randomAvatarGenerator(gender: GENDER | 0) {
    if (gender === 0) {
        const randomIndex = Math.floor(Math.random() * 9) + 1;
        return `${environment.s3Url}/arena-ai/public-assets/avatars/external_${randomIndex}.png`;
    }
    const men = [3, 5, 6, 7, 8, 11, 12, 13, 16, 18, 20, 23, 24, 27];
    const women = [1, 2, 4, 9, 10, 14, 15, 17, 19, 21, 22, 25, 26];

    const avatarArray = gender === GENDER.MALE ? men : women;

    const randomIndex = Math.floor(Math.random() * avatarArray.length);

    return `https://cdn.jsdelivr.net/gh/alohe/avatars/png/vibrent_${avatarArray[randomIndex]}.png`;
}
